import { Fragment, forwardRef } from 'react';

function defaultHandleKeyDown (e) {

}
export const Input = forwardRef(({name, className, value = "", size = 10, maxlength = 10, type = 'text', handleKeyDown = defaultHandleKeyDown}, ref) => {

    return (
        <Fragment>
            <input ref={ref} className={className} type={type} size={size} maxLength={maxlength} name={name} defaultValue={value} onKeyDown={handleKeyDown} />
        </Fragment>
    );
})