import { Fragment, forwardRef } from 'react';

export const Combo = forwardRef(({name, datas, selected = 0}, ref) => {
    return (
        <Fragment>
            <select ref={ref} name={name}>
                {datas.map(data => {
                    return (
                        <option value={data.value} selected={(data.value === selected) && "selected"}>{data.label}</option>
                    );
                })}
            </select>
        </Fragment>
    );
})