import { useState, useRef, useEffect, Fragment } from 'react';
import { Combo }                                 from '../components/Combo';
import { Input }                                 from '../components/Input';
import DatePicker, {registerLocale}              from 'react-datepicker';
import fr                                        from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

export const Schedules = () => {
    registerLocale('fr', fr);

    const initData = [{
        id: 1,
        date: '2023-01-09',
        heure_deb: '19:15',
        heure_fin: '20:30',
        glace: 'B-C',
        ligue: 'Soccer',
        groupe_1: 'CSOQ',
        chambre_1: '243-255',
        groupe_2: 'U21 F AAA',
        chambre_2: '259-273',
    }];

    const hourData = [{label: "01", value: 1}, {label: "02", value: 2}, {label: "03", value: 3}, {label: "04", value: 4}, {label: "05", value: 5}, {label: "06", value: 6}, {label: "07", value: 7}, {label: "08", value: 8},
                      {label: "09", value: 9}, {label: "10", value: 10}, {label: "11", value: 11}, {label: "12", value: 12}, {label: "13", value: 13}, {label: "14", value: 14}, {label: "15", value: 15}, {label: "16", value: 16},
                      {label: "17", value: 17}, {label: "18", value: 18}, {label: "19", value: 19}, {label: "20", value: 20}, {label: "21", value: 21}, {label: "22", value: 22}, {label: "23", value: 23}, {label: "00", value: 0}];

    const minuteData = [{label: "00", value: 0}, {label: "15", value: 15}, {label: "30", value: 30}, {label: "45", value: 45}, {label: "--", value: 59}, {label: "01", value: 1}, {label: "02", value: 2}, {label: "03", value: 3},
        {label: "04", value: 4}, {label: "05", value: 5}, {label: "06", value: 6}, {label: "07", value: 7}, {label: "08", value: 8}, {label: "09", value: 9}, {label: "10", value: 10}, {label: "11", value: 11},
        {label: "12", value: 12}, {label: "13", value: 13}, {label: "14", value: 14}, {label: "15", value: 15}, {label: "16", value: 16}, {label: "17", value: 17}, {label: "18", value: 18}, {label: "19", value: 19},
        {label: "20", value: 20}, {label: "21", value: 21}, {label: "22", value: 22}, {label: "23", value: 23}, {label: "24", value: 24}, {label: "25", value: 25}, {label: "26", value: 26}, {label: "27", value: 27},
        {label: "28", value: 28}, {label: "29", value: 29}, {label: "30", value: 30}, {label: "31", value: 31}, {label: "32", value: 32}, {label: "33", value: 33}, {label: "34", value: 34}, {label: "35", value: 35},
        {label: "36", value: 36}, {label: "37", value: 37}, {label: "38", value: 38}, {label: "39", value: 39}, {label: "40", value: 40}, {label: "41", value: 41}, {label: "42", value: 42}, {label: "43", value: 43},
        {label: "44", value: 44}, {label: "45", value: 45}, {label: "46", value: 46}, {label: "47", value: 47}, {label: "48", value: 48}, {label: "49", value: 49}, {label: "50", value: 50}, {label: "51", value: 51},
        {label: "52", value: 52}, {label: "53", value: 53}, {label: "54", value: 54}, {label: "55", value: 55}, {label: "56", value: 56}, {label: "57", value: 57}, {label: "58", value: 58}, {label: "59", value: 59}];

    let currentDate = new Date();
    let formattedDate = currentDate.getFullYear() + '-' + adjustTimeStr(currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    let currentEmplacement = 'PepsHockey';

    const [scheduleDatas, setScheduleDatas] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(formattedDate);
    const [unformatedSelectedDate, setUnformatedSelectedDate] = useState(currentDate);
    const [selectedEmplacement, setSelectedEmplacement] = useState(currentEmplacement);
    const [isLogged, setIsLogged] = useState(false);
    const [errorLogging, setErrorLogging] = useState(false);
    const [apiKey, setApiKey] = useState('');

    const heureDebutRef = useRef(0);
    const minuteDebutRef = useRef(0);
    const heureFinRef = useRef(0);
    const minuteFinRef = useRef(0);
    const glaceRef = useRef(0)
    const ligueRef = useRef(0);
    const groupe1Ref = useRef(0);
    const groupe2Ref = useRef(0);
    const chambre1Ref = useRef(0);
    const chambre2Ref = useRef(0);
    const loginRef = useRef(0);
    const passRef = useRef(0);

    useEffect(() => {
        if (isLoading && isLogged) {
            //call api

            fetch(`${process.env.REACT_APP_API}getHoraireArenaDatas?emplacement=${selectedEmplacement}&date=${selectedDate}&apiKey=${apiKey}`)
                .then(res => res.json())
                .then(
                    (result) => {
                        setScheduleDatas(result);
                        setIsLoading(false);
                    },
                    (error) => {
                        setIsLoading(false);
                    }
                )

        }
    }, [selectedDate, selectedEmplacement, isLogged])

    function handleChange (e) {
        e.preventDefault();
    }

    function handleKeyDown (e) {
        if (e.key === 'Enter') {
            let datas = `login=${loginRef.current.value}`;
            datas += `&password=${passRef.current.value}`;

            fetch(`${process.env.REACT_APP_API}loginModuleHoraire?${datas}`)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.valid === false) {
                            setErrorLogging(true);
                        } else if (result.valid === true) {
                            setErrorLogging(false);
                            setIsLogged(true);
                            setSelectedEmplacement(result.emplacement);
                            setApiKey(result.apiKey);
                        }

                    },
                    (error) => {
                        setErrorLogging(true);
                    }
                )
        }
    }

    function handleAddScheduleLine (e) {
        e.preventDefault();

        let datas = `emplacement=${selectedEmplacement}`;
        datas += `&date=${selectedDate}`;
        datas += `&heureDebut=${(parseInt(heureDebutRef.current.value) * 60 * 60) + (parseInt(minuteDebutRef.current.value) * 60)}`;
        datas += `&heureFin=${(parseInt(heureFinRef.current.value) * 60 * 60) + (parseInt(minuteFinRef.current.value) * 60)}`;
        datas += `&glace=${glaceRef.current.value}`;
        datas += `&ligue=${ligueRef.current.value}`;
        datas += `&groupe1=${groupe1Ref.current.value}`;
        datas += `&groupe2=${groupe2Ref.current.value}`;
        datas += `&chambre1=${chambre1Ref.current.value}`;
        datas += `&chambre2=${chambre2Ref.current.value}`;
        datas += `&apiKey=${apiKey}`;

        fetch(`${process.env.REACT_APP_API}saveNewHoraireArenaDatas?${datas}`)
            .then(res => res.text())
            .then(
                (result) => {

                    let newScheduleArr = [
                        ...scheduleDatas,
                        {
                            id: result,
                            date: selectedDate,
                            heure_deb: adjustTimeStr(heureDebutRef.current.value) + ":" + adjustTimeStr(minuteDebutRef.current.value),
                            heure_fin: adjustTimeStr(heureFinRef.current.value) + ":" + adjustTimeStr(minuteFinRef.current.value),
                            glace: glaceRef.current.value,
                            ligue: ligueRef.current.value,
                            groupe_1: groupe1Ref.current.value,
                            chambre_1: chambre1Ref.current.value,
                            groupe_2: groupe2Ref.current.value,
                            chambre_2: chambre2Ref.current.value,
                        }
                    ];

                    newScheduleArr.sort((a, b) => {
                        const heureA = (parseInt(a.heure_deb.split(":")[0]) * 60 * 60) + (parseInt(a.heure_deb.split(":")[1]) * 60);
                        const heureB = (parseInt(b.heure_deb.split(":")[0]) * 60 * 60) + (parseInt(b.heure_deb.split(":")[1]) * 60);

                        if (heureA < heureB) {
                            return -1;
                        }
                        if (heureA > heureB) {
                            return 1;
                        }

                        return 0;
                    });

                    setScheduleDatas(newScheduleArr);

                    heureDebutRef.current.value = '0';
                    minuteDebutRef.current.value = '0';
                    heureFinRef.current.value = '0';
                    minuteFinRef.current.value = '0';
                    glaceRef.current.value = '';
                    ligueRef.current.value = '';
                    groupe1Ref.current.value = '';
                    chambre1Ref.current.value = '';
                    groupe2Ref.current.value = '';
                    chambre2Ref.current.value = '';
                },
                (error) => {

                }
            )
    }

    function handleSave (e) {
        e.preventDefault();

        let datas = `heureDebut=${(parseInt(heureDebutRef.current.value) * 60 * 60) + (parseInt(minuteDebutRef.current.value) * 60)}`;
        datas += `&heureFin=${(parseInt(heureFinRef.current.value) * 60 * 60) + (parseInt(minuteFinRef.current.value) * 60)}`;
        datas += `&glace=${glaceRef.current.value}`;
        datas += `&ligue=${ligueRef.current.value}`;
        datas += `&groupe1=${groupe1Ref.current.value}`;
        datas += `&groupe2=${groupe2Ref.current.value}`;
        datas += `&chambre1=${chambre1Ref.current.value}`;
        datas += `&chambre2=${chambre2Ref.current.value}`;
        datas += `&scheduleId=${editingId}`;
        datas += `&emplacement=${selectedEmplacement}`;
        datas += `&apiKey=${apiKey}`;

        fetch(`${process.env.REACT_APP_API}editHoraireArenaDatas?${datas}`)
            .then(res => res.text())
            .then(
                (result) => {
                    setScheduleDatas(scheduleDatas.map(schedule => {
                        if (parseInt(schedule.id) === parseInt(editingId)) {
                            return {
                                id: schedule.id,
                                date: selectedDate,
                                heure_deb: adjustTimeStr(heureDebutRef.current.value) + ":" + adjustTimeStr(minuteDebutRef.current.value),
                                heure_fin: adjustTimeStr(heureFinRef.current.value) + ":" + adjustTimeStr(minuteFinRef.current.value),
                                glace: glaceRef.current.value,
                                ligue: ligueRef.current.value,
                                groupe_1: groupe1Ref.current.value,
                                chambre_1: chambre1Ref.current.value,
                                groupe_2: groupe2Ref.current.value,
                                chambre_2: chambre2Ref.current.value,
                            };
                        } else {
                            return schedule;
                        }
                    }));

                    setIsEditing(false);
                    setEditingId(0);
                },
                (error) => {

                }
            )
    }

    function adjustTimeStr (val) {
        if (val < 10) {
            val = `0${val}`;
        }
        return val;
    }

    function handleDelete (e) {
        e.preventDefault();

        let scheduleId = parseInt(e.target.name);

        let datas = `emplacement=${selectedEmplacement}`;
        datas += `&scheduleId=${scheduleId}`;
        datas += `&apiKey=${apiKey}`;

        fetch(`${process.env.REACT_APP_API}deleteHoraireArenaDatas?${datas}`)
            .then(res => res.text())
            .then(
                (result) => {
                    setScheduleDatas(scheduleDatas.filter(schedule => schedule.id != scheduleId));
                },
                (error) => {

                }
            )
    }

    function handleEdit (e) {
        e.preventDefault();

        let scheduleId = parseInt(e.target.name);
        setIsEditing(true);
        setEditingId(scheduleId);
    }

    function handleConnexion (e) {
        e.preventDefault();

        let datas = `login=${loginRef.current.value}`;
        datas += `&password=${passRef.current.value}`;

        fetch(`${process.env.REACT_APP_API}loginModuleHoraire?${datas}`)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.valid === false) {
                        setErrorLogging(true);
                    } else if (result.valid === true) {
                        setErrorLogging(false);
                        setIsLogged(true);
                        setSelectedEmplacement(result.emplacement);
                        setApiKey(result.apiKey);
                    }

                },
                (error) => {
                    setErrorLogging(true);
                }
            )
    }

    if (!isLogged) {
        return (
            <div id="loginContainer">
                <div className="text-black text-lg font-bold">Connexion system d'horaire aréna Netsign</div>
                <form id="loginTable" className="block top-0 left-0">
                    <table cellPadding={0} cellSpacing={1} width={1100}>
                        <tr>
                            <td className="loginCell" width={135} valign="middle" align="center">
                                Nom d'utilisateur :
                                <Input
                                    ref={loginRef}
                                    name="login"
                                    className="login"
                                    value=""
                                    size={22}
                                    maxlength={100}
                                    handleKeyDown={handleKeyDown}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="loginCell" width={135} valign="middle" align="center">
                                Mot de passe :
                                <Input
                                    ref={passRef}
                                    name="password"
                                    className="password"
                                    type="password"
                                    value=""
                                    size={22}
                                    maxlength={100}
                                    handleKeyDown={handleKeyDown}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="loginCell" width={135} valign="middle" align="center">
                                <input type="button" className="loginBtn" value="connexion" onClick={handleConnexion}/>
                            </td>
                        </tr>
                        {errorLogging &&  (
                            <td className="loginCell" width={135} valign="middle" align="center">
                                Problème de connexion, vérifier votre nom du'usage et mot de passe
                            </td>
                        )}
                    </table>
                </form>
            </div>
        );
    } else if (isLoading) {
        return <div id="container">Is Loading</div> ;
    }
    return (
        <div id="container">
            <div className="text-black text-lg font-bold">Mes horaires pour {selectedDate}</div>
            <div className="arenaDate"><DatePicker locale="fr" selected={unformatedSelectedDate} onChange={(date) => {
                setUnformatedSelectedDate(date);
                let newSlectedDate = date.getFullYear() + '-' + adjustTimeStr(date.getMonth() + 1) + '-' + date.getDate();

                if (selectedDate !== newSlectedDate) {
                    setSelectedDate(date.getFullYear() + '-' + adjustTimeStr(date.getMonth() + 1) + '-' + date.getDate());
                    setIsLoading(true);
                }
            }} withPortal />
            </div>
            <form id="frmTable" className="block top-0 left-0">
                <table cellPadding={0} cellSpacing={1} width={1100}>
                    <thead>
                        <tr>
                            <td className="wlineblue" height={8} colSpan={8}></td>
                        </tr>
                        <tr>
                            <td valign="middle" align="center" className="subtitle" width={50}></td>
                            <td valign="middle" align="center" className="subtitle">Heure debut</td>
                            <td valign="middle" align="center" className="subtitle">Heure fin</td>
                            <td valign="middle" align="center" className="subtitle">Glace</td>
                            <td valign="middle" align="center" className="subtitle">Ligue</td>
                            <td valign="middle" align="center" className="subtitle">Groupe</td>
                            <td valign="middle" align="center" className="subtitle">Chambre</td>
                            <td valign="middle" align="center" className="subtitle" width={75}></td>
                        </tr>
                        <tr>
                            <td className="h-10"></td>
                        </tr>
                    </thead>
                    <tbody id="contentTable1">
                        {scheduleDatas.map(schedule => {

                            if (isEditing && editingId == schedule.id) {
                                return (
                                    <Fragment key={schedule.id}>
                                        <tr className="top_entry">
                                            <td rowSpan={2} width={50} className="paircell" align="center"></td>
                                            <td className="paircell" width={135} rowSpan={2} valign="middle" align="center">
                                                <Combo
                                                    ref={heureDebutRef}
                                                    name="hDebut"
                                                    datas={hourData}
                                                    selected={parseInt(schedule.heure_deb.split(":")[0])}
                                                />
                                                :
                                                <Combo
                                                    ref={minuteDebutRef}
                                                    name="mDebut"
                                                    datas={minuteData}
                                                    selected={parseInt(schedule.heure_deb.split(":")[1])}
                                                />
                                            </td>
                                            <td className="paircell" width={135} rowSpan={2} valign="middle" align="center">
                                                <Combo
                                                    ref={heureFinRef}
                                                    name="hFin"
                                                    datas={hourData}
                                                    selected={parseInt(schedule.heure_fin.split(":")[0])}
                                                />
                                                :
                                                <Combo
                                                    ref={minuteFinRef}
                                                    name="mFin"
                                                    datas={minuteData}
                                                    selected={parseInt(schedule.heure_fin.split(":")[1])}
                                                />
                                            </td>
                                            <td className="paricell" rowSpan={2} valign="middle" align="center">
                                                <Input
                                                    ref={glaceRef}
                                                    name="glace"
                                                    className="glace_new"
                                                    value={schedule.glace}
                                                    size={22}
                                                    maxlength={15}
                                                />
                                            </td>
                                            <td className="paricell" rowSpan={2} valign="middle" align="center">
                                                <Input
                                                    ref={ligueRef}
                                                    name="ligue"
                                                    className="ligue_new"
                                                    value={schedule.ligue}
                                                    size={22}
                                                    maxlength={15}
                                                />
                                            </td>
                                            <td valign="middle" align="center">
                                                <Input
                                                    ref={groupe1Ref}
                                                    name="groupe1"
                                                    className="groupe1_new"
                                                    value={schedule.groupe_1}
                                                    size={22}
                                                    maxlength={30}
                                                />
                                            </td>
                                            <td valign="middle" align="center">
                                                <Input
                                                    ref={chambre1Ref}
                                                    name="chambre1"
                                                    className="chambre1_new"
                                                    value={schedule.chambre_1}
                                                    size={12}
                                                    maxlength={8}
                                                />
                                            </td>
                                            <td rowSpan={2} align="center" valign="middle" className="edit">
                                                <img className="save_active" name={schedule.id} src={require('../images/save_active.jpg')} onClick={handleSave}/>
                                            </td>
                                        </tr>
                                        <tr className="bottom_entry">
                                            <td valign="middle" align="center">
                                                <Input
                                                    ref={groupe2Ref}
                                                    name="groupe2"
                                                    className="groupe2_new"
                                                    value={schedule.groupe_2}
                                                    size={22}
                                                    maxlength={30}
                                                />
                                            </td>
                                            <td valign="middle" align="center">
                                                <Input
                                                    ref={chambre2Ref}
                                                    name="chambre2"
                                                    className="chambre2_new"
                                                    value={schedule.chambre_2}
                                                    size={12}
                                                    maxlength={8}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8}><hr /></td>
                                        </tr>
                                    </Fragment>
                                );
                            } else {

                                return (
                                    <Fragment key={schedule.id}>
                                        <tr className="top_entry">
                                            <td width={50} align="center" rowSpan={2}></td>
                                            <td rowSpan={2} align="center" valign="middle">{schedule.heure_deb}</td>
                                            <td rowSpan={2} align="center" valign="middle">{schedule.heure_fin}</td>
                                            <td rowSpan={2} align="center" valign="middle">{schedule.glace}</td>
                                            <td rowSpan={2} align="center" valign="middle">{schedule.ligue}</td>
                                            <td align="center" valign="middle">{schedule.groupe_1}</td>
                                            <td align="center" valign="middle">{schedule.chambre_1}</td>
                                            <td rowSpan={2} align="center" valign="middle" className="edit">
                                                {!isEditing && <img className="edit_active" name={schedule.id} src={require('../images/edit_active.jpg')} onClick={handleEdit}/>}
                                                {!isEditing && <img className="delete_active" name={schedule.id} src={require('../images/delete_active.jpg')} onClick={handleDelete}/>}
                                            </td>
                                        </tr>
                                        <tr className="bottom_entry">
                                            <td align="center" valign="middle">{schedule.groupe_2}</td>
                                            <td align="center" valign="middle">{schedule.chambre_2}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={8}><hr /></td>
                                        </tr>
                                    </Fragment>
                                );
                            }
                        })}
                    </tbody>
                    <tfoot id="footerTable1">
                    {
                        !isEditing && (
                            <Fragment>
                                <tr className="top_entry">
                                    <td rowSpan={2} width={50} className="paircell" align="center"></td>
                                    <td className="paircell" width={135} rowSpan={2} valign="middle" align="center">
                                        <Combo
                                            ref={heureDebutRef}
                                            name="hDebut"
                                            datas={hourData}
                                        />
                                        :
                                        <Combo
                                            ref={minuteDebutRef}
                                            name="mDebut"
                                            datas={minuteData}
                                        />
                                    </td>
                                    <td className="paircell" width={135} rowSpan={2} valign="middle" align="center">
                                        <Combo
                                            ref={heureFinRef}
                                            name="hFin"
                                            datas={hourData}
                                        />
                                        :
                                        <Combo
                                            ref={minuteFinRef}
                                            name="mFin"
                                            datas={minuteData}
                                        />
                                    </td>
                                    <td className="paricell" rowSpan={2} valign="middle" align="center">
                                        <Input
                                            ref={glaceRef}
                                            name="glace"
                                            className="glace_new"
                                            value=""
                                            size={22}
                                            maxlength={15}
                                        />
                                    </td>
                                    <td className="paricell" rowSpan={2} valign="middle" align="center">
                                        <Input
                                            ref={ligueRef}
                                            name="ligue"
                                            className="ligue_new"
                                            value=""
                                            size={22}
                                            maxlength={15}
                                        />
                                    </td>
                                    <td valign="middle" align="center">
                                        <Input
                                            ref={groupe1Ref}
                                            name="groupe1"
                                            className="groupe1_new"
                                            value=""
                                            size={22}
                                            maxlength={30}
                                        />
                                    </td>
                                    <td valign="middle" align="center">
                                        <Input
                                            ref={chambre1Ref}
                                            name="chambre1"
                                            className="chambre1_new"
                                            value=""
                                            size={12}
                                            maxlength={8}
                                        />
                                    </td>
                                    <td rowSpan={2} valign="middle" align="center" className="paircell">
                                        <img className="add_active" src={require('../images/add_active.jpg')} onClick={handleAddScheduleLine}/>
                                    </td>
                                </tr>
                                <tr className="bottom_entry">
                                    <td valign="middle" align="center">
                                        <Input
                                        ref={groupe2Ref}
                                        name="groupe2"
                                        className="groupe2_new"
                                        value=""
                                        size={22}
                                        maxlength={30}
                                        />
                                        </td>
                                        <td valign="middle" align="center">
                                        <Input
                                        ref={chambre2Ref}
                                        name="chambre2"
                                        className="chambre2_new"
                                        value=""
                                        size={12}
                                        maxlength={8}
                                        />
                                    </td>
                                </tr>
                            </Fragment>
                        )
                    }
                    </tfoot>
                </table>
            </form>
        </div>
    )
}