import logo from './logo.svg';
import './App.css';
import { Schedules } from './pages/Schedules.jsx';

function App() {
  return (
    <div className="App">
      <header>
        <Schedules />
      </header>
    </div>
  );
}

export default App;
